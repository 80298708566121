// Panel class
// -----------------------------------------------------
.panel-width-0, .panel-width-1, .panel-width-2, .panel-width-3, .panel-width-4, .panel-width-5, .panel-width-6, .panel-width-7, .panel-width-8, .panel-width-9 {
    .panel-columns {
        grid-template-columns: 100% !important;
    }
}
.panel{
    width: 100% !important;
    height: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    box-shadow: 0 0 6px 0 #00000026;
    background-color: rgb(255, 255, 255);
    // padding: 0 14px;
    z-index: 5;
    transition: all 0.5s;
    overflow-x: hidden;
    * {
        transition: all 0.5s;
    }
    .loading-placeholder {
        width: 100%;
    }
    .panel-child-hidden {
        opacity: 0;
        z-index: -1000;
        position: relative;
        transform-origin: 0 0;
        transform: scale(0);
        pointer-events: none;
    }
    .panel-label {
        margin: 10px 0 2px;
        padding: 0 14px;
        transform-origin: left;
        // display: flex;
        display: none;
        font-size: 10px;
        align-items: center;
        .collapse-btn {
            margin-left: auto;
            padding: 0 !important;

        }
        .panel-label-arrow {
            font-size: 16px !important;
        }
        .label-content {
            display: flex;
            white-space: nowrap;
        }
    }
    .panel-header {
        width: 100%;
        min-height: 45px;
        max-height: 45px;
        padding: 0 14px 0px 14px;
        box-sizing: border-box;
        display: grid;
        align-items: end;
        .panel-header-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            .loading-placeholder {
                max-height: 35px;
            }
            h4 {
                color: #191919;
                font-size: 14px !important;
            }
            .quick-action:hover {
                color: $primary_main;
            }
        }
    }
    .panel-body{
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 14px 30px 14px;
        background-color: white;
        display: flex;
        flex-direction: column;
        box-shadow: 0 27px 10px -21px white inset;
        table{
            width: 100%;
            td{
                font-size: 14px;
                padding: 2px;
                color: grey;
            }
        }
        .panel-columns {
            display: grid;
            grid-template-columns: 49% 49%;
            grid-column-gap: 2%;
            margin-top: 10px;
            .panel-column {
                height: fit-content;
                display: grid;
                grid-gap: 15px;
            }
        }
    }
    nav{
        width: 100%;
        overflow-x: clip;
        list-style: none;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        .list-grouping-header{
            font-size: 12px;
            font-weight: 700;
            color: rgb(80, 80, 80);
            margin: 10px 0 5px 0;
        }
        .selected {
            color: $primary_main !important;
            font-weight: 400;
            .type-icon {
                color: $primary_main !important;
            }
        }
        .state-subject {
            * {
                color: black;
            }
        }
        .panel-list-item:not(:last-of-type) {
            border-bottom: 0.5px solid #eceef3;
        }
        .panel-list-item{
            height: 44px;
            box-sizing: content-box;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            * {
                cursor: pointer;
                transition: all 0.5s, color 0.1s !important;
            }
            svg{
                font-size: 20px;
                color: rgb(171, 171, 171);
            }
            label {
                width: 100%;
                display: flex;
                padding-left: 8px;
                justify-content: space-between;
                align-items: center;
            }
        }
        .panel-list-item:hover{
            svg:first-child{
                margin-left: 4px;
            }
        }
        .panel-list-item:focus{
            // background-color: whitesmoke;
            box-shadow: 0 -8px 8px -8px #0000001b inset, 0 8px 8px -8px #0000001b inset;
            outline: none;
        }
    }
}


// Minimised panel
// -----------------------------------------------------
.panel-minimised {
    max-width: 35px !important;
    .panel-label {
        width: 16px;
        transform: matrix(0.00,1.00,-1.00,0.00,0,0);
        margin: 0px 0px 0px 19px;
        display: flex !important;
        padding: 0;
        // flex-direction: row-reverse;
        // margin-top: 64px;
        .panel-label-arrow {
            transform: rotateZ(90deg);
        }
    }
    .panel-header {
        border: none !important;
        visibility: collapse;
    }
    .panel-body {
        visibility: collapse;
    }
}

.panel-hidden {
    width: 0 !important;
    visibility: collapse;
}


// Question progress
// -----------------------------------------------------
.panel-question{
    z-index: 7;
    .panel-label {
        p, svg{
            font-size: 12px;
            margin: 0;
        }
    }
    .panel-body {
        margin-top: 16px;
        .question {
            display: grid !important;
            row-gap: 12px !important;
        }
    }
}



// Folder panel
// -----------------------------------------------------
.panel-folder{
    z-index: 10;
}

// Breadcrums for navigating folders
// -----------------------------------------------------
.breadcrums{
    max-width: 380px;
    display: flex;
    align-items: center;
    // font-weight: 500;
    .breadcrumb-delimiter {
        margin: 0 5px;
        cursor:default
    }
    a{
        white-space: nowrap;
        cursor: pointer;
        border-bottom: 1px solid rgba(128, 128, 128, 0);
    }
    a:is(:first-child) {
        display: contents;

    }
    a:not(:last-child) {

        overflow: hidden;
        text-overflow: ellipsis;
        align-items: center;
        // font-weight: 400 !important;
        // color: #8b8b8b;
    }
    a:is(:last-child) {
        width: auto;
    }
    a:hover{
        color: $theme-color;
    }
    a:focus{
        outline: none;
        background-color: whitesmoke;
        border-color: $secondary_main !important;
    }
    svg{
        font-size: 15px;
        // color: #8b8b8b;
    }
}



@media only screen and (max-width: 600px) {
    .panel-label {
        display: flex !important;
    }
}