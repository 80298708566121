.MuiPaper-root {
    .modal-close {
        position: absolute;
        padding: 0;
        top: 10px;
        right: 10px;
        svg {
            font-size: 16px;
            color: #0000003b;
        }
    }
}

.MuiDialog-paper{
    max-width: none !important;
    overflow: hidden !important;
}
.card{    
    width: min-content;
    height: fit-content;
    max-height: 90vh;
    background-color: white;        
    padding: 25px 30px 30px 30px;     
    box-sizing: border-box;
    box-shadow: 0 0 7px 0px #0000003b;    
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    z-index: 1000;
    .card-header{    
        svg{
            font-size: 18px !important;
            color: grey;
        }
    }
    .card-body{
        height: 100%;
        // display: contents;
    }
    .card-footer{
        display: flex;
        justify-content: space-between;        
        margin-top: 10px;
    }
}
.card-code {
    .card-body {
        padding: 8px;
        font-family: monospace;
        background-color: rgb(233, 233, 233);
        white-space: pre;
        overflow: scroll;
        overflow-wrap: scroll;
    }
}
.card-xs{
    width: 84vw;
    max-width: 300px;    
}
.card-sm{
    width: 84vw;
    max-width: 360px;    
}
.card-md{
    width: 84vw;
    max-width: 500px;   
}
.card-lg{
    width: 84vw;
    max-width: 700px;
    min-height: 200;
}
.card-xl{
    width: 84vw;
    max-width: 900px;
    // min-height: 420px;
}


// Help
// ---------------------------------------------------------------------
.MuiTooltip-tooltip {
    background-color: rgb(89, 89, 89) !important;
    font-weight: unset !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.126) !important;
    * {
        color: white !important;
    }
}