// Navbar/Header
// -----------------------------------------------------
.nav-header {
    height: 50px;
    max-height: 50px !important;
    min-height: 50px !important;
    // background: linear-gradient(100deg, $primary_dark -10%, #48628e 50%, $primary_dark 110%) !important;
    background: linear-gradient(-45deg,#79535D,#3A3B80);
    // background-color: grey !important;
    .MuiToolbar-root {
        min-height: 50px !important;
    }
    .header-status {
        color: rgb(161, 161, 161);
        display: flex;
        align-items: center;
        text-align: right;
        margin-left: auto;
        small {
            font-size: 8px !important;
        }
        cursor: pointer;
        button {
            padding: 0;
            svg {
                font-size: 20px;
            }
        }
    }
    .grow {
        flex-grow: 1;
    }
    .logo-button {    
        height: 25px;
        margin: 0;
        padding: 4px 15px;
        img, svg{
            width: auto;
            height: 100%;
        }
    }
    .main-menu-toggler {
        padding: 0;
        svg {
            font-size: 30px;
        }
    }
    .main-menu{
        min-width: 250px;
    }
    .desktop-section {
        display: flex;
    }
    .mobile-section {
        display: none,
    }
}


// Menu - inline
.inline-menu {
    ul {
        min-width: 150px !important;
    }
    .inline-menu-title {
        font-size: 10px !important;
        // background-color: whitesmoke;
        // border-bottom: 0.5px solid $secondary_light;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .inline-menu-item {
        width: 100%;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg {
            font-size: 12px;
        }   
    }
}


// Menu - main
// -----------------------------------------------------
.main-menu{
    min-width: 270px;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #1f1e23;
    color: $text-light;
    * {
        font-weight: 400;
    }
    .main-menu-header{
        padding: 28px 20px;
        display: grid;
    }
    .main-menu-body{
        padding: 0px 20px;
        * {
            font-weight: 300;
            font-size: 14px;
            color: $text-light;
        }
        .main-menu-nav{
            display: flex;
            flex-direction: column;
            margin-top: 15px;
            .main-menu-link {
                height: 45px;
                width: auto;
                display: flex;
                justify-content: flex-start;
                align-items: center !important;
                text-transform: none;
                padding: 0;
                font-size: 18px;
                * {
                    cursor: pointer;
                }
                svg {
                    padding: 10px 10px 10px 0;
                }
            }
        }
    }
    .main-menu-footer{
        font-size: 14px;
        color: rgb(165, 165, 165);
        margin-top: auto;
        padding: 12px;
        display: grid;
        grid-gap: 2px;
        svg{
            margin-bottom: -2px;
            font-size: 14px;
        }
    }
}



// Tabs
.MuiTabs-root {
    min-height: unset !important;
    height: 30px;
    button {
        min-height: unset !important;
        font-size: 12px;
        padding-top: 7px;
        padding-bottom: 10px;
    }
}



// Mobile navbar
// -----------------------------------------------------
@media only screen and (max-width: 600px) {
    .nav-header {
        .desktop-section {
            display: none;
        }
        .mobile-section {
            display: flex,
        }
    }
}