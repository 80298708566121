// Generic table
// -----------------------------------------------------
table {
    border-spacing: 0;
    margin: 10px 0;
    background: $table-row-background-color;
    thead {
        tr td {
            border-bottom: 1px solid #c2c2c2;
            background-color: $table-heading-background-color !important;
        }
    }
    tr {
        .warning-cell {
            color: $error_main;
            position: absolute;
            width: 100%;
        }
        td {
            padding: 4px 8px 4px 0 !important;
            font-size: 12px !important;
            svg {
                font-size: 14px !important;
            }
            .MuiInput-root::before, .MuiInput-root::before {
                border-bottom: none !important;
            }
            .MuiFormControl-root, .MuiInput-root {
                width: 100%;
                height: 18px !important;
                input, .MuiSelect-select {
                    font-size: 12px;
                }
                svg {
                    font-size: 14px;
                }
                .MuiSelect-select {
                    padding: 0 !important;
                }
            }
        }
    }
}


// Detail section absence table
// -----------------------------------------------------
.detail-section .absence-row {
    .section-cell:nth-child(1), .section-cell:nth-child(2), .section-cell:nth-child(3) {
        max-width: 80px;
    }
}


// Detail section table
// -----------------------------------------------------
.editing {
    outline: auto;
    outline-color: #6d7b94;
    outline-offset: 1px;
    outline-style: dashed;
    outline-width: 1.5px;
}
.section-header {
    display: grid;
    padding: 6px 0;
    color: #999999;
    background-color: white;
    // border: 1px solid red;
    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .section-certainty {
        display: flex;
        justify-content: space-between;
        font-size: 10px;
        padding: 6px 0 0 0;
        .MuiInput-root {
            height: 12px;
            box-sizing: border-box;
            overflow: visible;
            .MuiSelect-select {
                font-size: 10px !important;
                padding: 0 18px 0 0;
                background-color: transparent;
            }
        }
    }
}
.section-subheader  {
    border-bottom: 1px solid #e2e2e2;
    margin: 2px 3px 0 3px;
    padding: 3px 5px 5px 5px;
    h5 {
        font-weight: 400;
        color: grey;
    }
}
.minimised .section-table {
    display: none;
}
.enquiry-detail .detail-section {
    margin-bottom: 30px;
}
.detail-section-clear {
    padding: 0 8px;
}
.detail-section { 
    margin: 0;
    position: relative;
    margin-bottom: 8px;
    svg {
        font-size: 16px;
    }
    .attribute-row {
        background-color: white;
    }
    .report-row {
        color: $secondary_main;
    }
    .status-row {
        border-bottom: 1px solid #e2e2e2;
        background: $table-heading-background-color !important;
    }
    .section-row:hover {
        background-color: rgb(241, 241, 241);
    }
    .transparent {
        background-color: transparent !important;
    }
    .section-table {
        display: flex;
        flex-direction: column;
        background: $table-row-background-color;
        overflow-x: visible !important;
        
        button {
            line-height: 8px;
            font-size: 10px !important;
            padding: 0;
            margin-left: auto;
        }
        .section-row {
            display: flex;
            align-items: center;
            background-color: transparent;
            padding: 0 4px;
            .section-cell {
                width: 100%;
                width: -moz-available;          /* WebKit-based browsers will ignore this. */
                width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
                width: fill-available;
                padding: 4px 2px;
                display: grid;
                align-items: center;
                font-size: 12px;
                overflow: hidden;
                // box-sizing: border-box;
                .MuiFormLabel-root {
                    line-height: 10px;
                }
                .MuiInput-input {
                    padding: 0 !important;
                }
            }
        }
    }
}

// Form automation details
// ---------------------------------------------------------------------
.auto-form-map-diagnostics {
    .diagnostics-section {
        margin-bottom: 15px;
    }
    .diagnostics-section-header {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        margin: 10px 0 0 10px;
        button {
            margin-left: auto !important;
        }
    }
    .diagnostics-field:nth-child(even){
        background-color: #f9f9f9;
    }
    .diagnostics-field {
        display: grid;
        grid-template-columns: 55% 30% 15%;
        margin: 4px 0;
        padding: 1px 10px;
        font-size: 12px;
        span:is(:last-child) {
            height: fit-content;
            margin: auto 0;
            font-weight: 500;
        }
        .genuine-value {
            color: $success_main;
        }
        .guessed-value {
            color: $warning_main;
        }
        .false-value {
            color: $error_main;
        }
    }
    .column-headers {
        font-weight: 500;
        border-bottom: 1px solid #d0d0d0;
        margin-bottom: 0;
        background-color: white !important;
    }
}


// Report Management table
// -----------------------------------------------------
.table-report-management {
    height: inherit;
    .ReactVirtualized__Table{
        // All cells
        .ReactVirtualized__Table__headerColumn:first-child, .ReactVirtualized__Table__rowColumn:first-child {
            padding-left: 10px;
        }
        .ReactVirtualized__Table__headerColumn:last-child, .ReactVirtualized__Table__rowColumn:last-child {
            padding-right: 10px;
        }
    }
}

// React virtualized table
// -----------------------------------------------------
.ReactVirtualized__Table{

    transition: all none !important;

    .ReactVirtualized__Table__Grid:focus{ outline: none !important }

    .ReactVirtualized__Table__headerColumn {
        display: flex;
        align-items: center;
        padding-right: 3px;
    }
    .ReactVirtualized__Table__headerTruncatedText, .ReactVirtualized__Table__row .ReactVirtualized__Table__rowColumn{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ReactVirtualized__Table__row, .ReactVirtualized__Table__headerRow{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        box-sizing: border-box;
        padding: 0 8px 0 8px;
        padding-right: unset !important;
    }
    .ReactVirtualized__Table__headerRow{
        font-weight: 600;
        box-shadow: 0 0 4px 0 #00000047;
        font-size: 11px;
        z-index: 2;
        position: relative;
    }
    .ReactVirtualized__Table__row:focus {
        background-color: whitesmoke !important;
    }
    .checkbox-column{
        display: flex;
        align-items: center;
        input{
            width: 15px;
            height: 15px;
            margin: auto;
            position: relative;
            z-index: 1000;
        }
    }
    .ReactVirtualized__Table__row:nth-child(even){
        // background-color: #f9f9f9;
    }
}