// Generic view class
// -----------------------------------------------------
.view {
    height: 100%;    
    padding: 0 !important;
    display: flex;    
    overflow-y: hidden;     
    overflow-x: hidden;
    .view-header {
        display: flex;
        justify-content: space-between;
        h1,h2,h3,h4,h5,h6 {
            margin: 0 !important;
        }
    }
}
.view-banner {
    padding: 9px 25px 9px 25px;
    color: rgb(105, 105, 105);
    background-color: white;
    border-bottom: 1px solid lightgrey;      
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    .title {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    // button {
    //     padding: 0 !important;
    // }
    a {
        svg {
            font-size: 18px;
            color: rgb(167, 167, 167);
        }
    }
    h1,h2,h3,h4,h5 {
        font-size: 10px;
        font-weight: 400;
        // text-transform: uppercase;
    }  
}


// Main view
// -----------------------------------------------------
.view-main {
    // Panel widths up to 650
    background-color: #00000006;
    
}
.panel-width-1, .panel-width-2, .panel-width-3, .panel-width-4, .panel-width-5, .panel-width-6 {
    .enquiry-cta {
        display: none !important;
    }
}
.report {
    margin-bottom: 14px;
    .report-header {
        background-color: #f1f1f1;
        padding: 10px;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 20px auto 16px 16px 16px;
        align-items: flex-start;
        h5 {
            font-weight: 600;
            // text-decoration: underline;
            color: $primary_main;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        svg {
            font-size: 16px;
        }
    }
}
.hidden {
    max-height: 3px !important;
}
.form-automation {
    // flex-wrap: wrap;
    // overflow: hidden;
    background-color: $table-row-background-color;
    margin-bottom: 20px;
    .form-automation-header {
        display: flex; 
        align-items: center; 
        justify-content: space-between;
        h5 {
            color: $primary_main
        }
    }
    .form-automation-details {
        display: flex;
        margin-top: 15px;
        .chart {
            margin: 0 auto;
            display: flex;
            canvas {
                margin: auto;
                max-width: 120px;
                max-height: 120px;
            }
        }
    }
    .form-automation-page-bar {
        position: absolute;
        width: 100%;
        background-color: #f1f1f1;
        .indicator {
            height: 4px;
            background-color: #c6c9d1;
            margin-right: auto;
        }
        .page-limit-indicator {
            height: 4px;
            width: 8px;
            background-color: #526485;
            margin-right: auto;
            position: relative;
            margin-top: -4px;
            // border-radius: 10px;
            // border: 1px solid white;
        }
    } 
}
.panel-width-0, .panel-width-1, .panel-width-2, .panel-width-3, .panel-width-4, .panel-width-5 {
    .form-automation {
        .form-automation-details {
            display: flex;
            gap: 20px;
            flex-direction: column-reverse;
            .chart {
                margin: 0;
            }
        }
    }
}
.enquiry-status-indicator {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    color: rgb(143, 143, 143);
    .status-details {
        height: 14px;
        display: flex;
        align-items: baseline;
        margin-bottom: 6px;
        small {
            background-color: white;
            margin-left: 8px;
            font-size: 11px;
        }
        button {
            font-size: 9px !important;
            background-color: white;
            opacity: 0;
            pointer-events: none;
            padding: 0;
            margin-top: 2px;
            margin-left: -10px !important;
            transition: margin-left 0.3s;
        }
    }
    .progress {
        height: 2px;
        width: 100%;
        background-color: rgb(223, 223, 223);
        overflow: hidden;
        border-radius: 10px;
        .progress-bar {
            height: 100%;
            width: 100%;
            background-color: $primary_light;
        }
    }
    .progress-nodes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .reached {
            background-color: $primary_main !important;
        }
        .progress-node {
            width: 6px;
            height: 6px;
            background-color: $secondary_light;
            border-radius: 10px;
            margin-top: -4px;
        }
    }
}
.running .progress-bar{
    box-shadow: 10px 0px 5px 0 $primary_main;
}
// .enquiry-status-indicator:hover {
//     .status-details {
//         gap: 0;
//         small {
//             position: absolute;
//             opacity: 0;
//             margin-left: 10px;
//         }
//         button {
//             opacity: 1;
//             pointer-events: all;
//             margin-left: 4px !important;
//         }
//     }
// }

// Settings view
// -----------------------------------------------------
.view-settings {
    overflow-y: scroll;
    background-color: white;
    .settings-color-chart {    
        display: grid; 
        grid-template-columns: 16.5% 16.5% 16.5% 16.5% 16.5% 16.5%;
        justify-content: space-between; 
        margin: 20px 0;

        .color-chart-sample-group {
            width: 100%;
            .color-chart-sample {
                width: 100%;
                height: 50px;
                text-align: center;
                display: grid;
                align-items: center;
                // box-shadow: 0 0 10px 0 #00000021;
            }
        }
    }
    .icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .icons-item {
            display: grid;
            text-align: center;
            margin: 10px;
            svg {
                margin: auto;
            }
        }
    }
    .settings-section {
        margin: 10px 0;
        padding: 10px 0;
        border-bottom: 0.5px solid rgb(201, 201, 201);
    }
    .form-demo {
        display: grid;
        grid-gap: 0 5%;
        grid-template-columns: 20% 20% 20% 20%;
    }
    .panel-width-8, .panel-width-9, .panel-width-10 {
        .form-demo {
            grid-template-columns: 30% 30% 30%;
            grid-gap: 0 3%;
        }
    }
    .panel-width-5, .panel-width-6, .panel-width-7 {
        .form-demo {
            grid-template-columns: 45% 45%;
            grid-gap: 0 10%;
        }
    }
    .panel-width-1, .panel-width-2, .panel-width-3, .panel-width-4 {
        .form-demo {
            grid-template-columns: 100%;
        }
    }
}



// Guide view
// -----------------------------------------------------
.view-guide {
    width: 100%;
    min-height: 80vh;
    min-width: 80vw;
    display: grid;
    .panel-header, .guide-content {
        width: 100%;
        max-width: 900px;
        margin: auto;
    }
}


// Guest view
// -----------------------------------------------------
.view-guest {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    #tsparticles canvas {
        background: linear-gradient(-45deg,#c46f33,#353983,#b76118);
        background-size: 300% 300%;
        animation: gradient 15s ease infinite;
        z-index: -10 !important;
        // background-color: rgb(89, 89, 89);
    }
    .view-guest-footer{
        position: absolute;
        bottom: 20px; 
        right: 20px;
        color: rgba(255, 255, 255, 0.774);
    }
    .MuiInput-root {
        width: 100% !important;
    }
}


// Form automaion view
// -----------------------------------------------------
.view-form-automation {
    padding: 20px !important;
    overflow-y: scroll;
    display: grid;
}


// Report management view
// -----------------------------------------------------
.view-report-management {

    .accepted {background-color: #ffff62;}
    .in_progress {background-color: #74e783;}
    .not_started {background-color: rgb(255, 187, 0);}
    .rejected {background-color: #b34446;color: white}
    .uncertain {background-color: #d0d0d0;}

    .report-management-table-panel {
        * {
            transition: none;
        }
        .panel-body {
            padding: 0 !important;
        }
    }
    
    .report-management-detail {
        // border-top: 0.5px solid #80808042;
        margin-top: 20px;
        padding-top: 20px;
        table {
            width: 100%;
            .left-col {
                width: 100px;
            }
        }
        .footer {
            padding: 20px 0;
        }
    }
}